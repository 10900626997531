<template>
    <section class="wrapper"></section>
</template>

<script>
import useUserStore from '@/stores/userStore'

export default {
    name: 'WalletComponent',

    async mounted() {

        const { showTorusWallet } = useUserStore()
        // Redirects to torus wallet
        await showTorusWallet()

        this.$router.push({ name: 'home' })
    },
}
</script>

<style scoped>
/* Your component styles go here */
</style>
