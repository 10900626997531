<template>
    <section class="wrapper">
        <div class="container">
            <div class="row">
                <div class="text-container">
                    <div>
                        <h5 class="subtitle">{{ $t('home.get-start.subtitle') }}</h5>
                        <h2 class="title">{{ $t('home.get-start.title') }}</h2>
                        <p class="text">
                            {{ $t('home.get-start.text') }}
                        </p>
                        <router-link class="btn" to="/lottery">{{ $t('home.get-start.play') }}!</router-link>
                    </div>
                </div>
                <div class="img-wrapper">
                    <div>
                        <img src="@/assets/images/home/get-start/get-start.png" alt="get-start" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.wrapper {
    background: #0b122e;

    .text-container {
        position: relative;
        width: 100%;
        display: flex;
        align-self: center;
        text-align: left;

        .btn-link {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 600;
            padding: 14px 35px;
            display: inline-block;
            color: #fff;
            border: 0px;
            border-radius: 50px;
            cursor: pointer;
            transition: all 0.3s ease-in;
            background-image: -webkit-gradient(linear, right top, left top, from(#ff344d), to(#d2273c));
            background-image: -webkit-linear-gradient(right, #ff344d, #d2273c);
            background-image: -o-linear-gradient(right, #ff344d, #d2273c);
            background-image: linear-gradient(to left, #ff344d, #d2273c);
        }
    }


    .img-wrapper {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.row {
    flex-direction: column-reverse;
}

@media screen and (min-width: 768px) {
    .row {
        flex-direction: row;
    }
}

@media screen and (min-width: 992px) {
    .text-container {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;

        .title {
            font-size: 60px;
        }
    }

    .text {
        font-size: 24px;
        line-height: 34px;
    }

    .img-wrapper {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
}
</style>
