import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@fortawesome/fontawesome-free/css/all.css'
import Countdown from 'vue3-flip-countdown'
import VueClickAway from 'vue3-click-away'
import 'vue3-carousel/dist/carousel.css'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import VueGtag from 'vue-gtag'

import enMessages from './locales/json/en.json'
import frMessages from './locales/json/fr.json'
import jpMessages from './locales/json/en.json'
import uaMessages from './locales/json/ua.json'
import './base.css'

const messages = {
    en: enMessages,
    fr: frMessages,
    jp: jpMessages,
    ua: uaMessages
}

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    defaultLocale: 'en',
    globalInjection: true,
    messages,
})

const pinia = createPinia()

createApp(App)
    .use(i18n)
    .use(pinia)
    .use(router)
    .use(Countdown)
    .use(VueClickAway)
    .use(VueGtag, {
        config: {
            id: 'G-E4BM1XJZWZ',
        },
    })
    .mount('#app')
