import HowToPlayBalloon from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayBalloon.vue'
import HowToPlayCoinflip from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayCoinflip.vue'
import HowToPlayDice from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayDice.vue'
import HowToPlayHilo from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayHilo.vue'
import HowToPlayMines from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayMines.vue'
import HowToPlayMoon from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayMoon.vue'
import HowToPlayRoulette from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayRoulette.vue'
import HowToPlayPlinko from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayPlinko.vue'
// import HowToPlayPredictBTC from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayPredictBTC.vue'
// import HowToPlayPredictETH from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayPredictETH.vue'
import HowToPlayDomino from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayDomino.vue'
import HowToPlayGinRummy from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayGin-Rummy.vue'
import HowToPlayCrazy8s from '@/components/GamesCatalog/games-text-how-to-play/HowToPlayCrazy8s.vue'
import HowToPlaySnakeLadders from '@/components/GamesCatalog/games-text-how-to-play/HowToPlaySnakeLadders.vue'
import { setChildProductId } from '@/helpers/demoGames/setChildProductId'

export const LANGUAGE = 'en'
export const COINS_VALUE = 600000
export const CURRENCY = 'USDT'
export const WALLET_TYPE = 'MONEY_TRANSFER'
export const ENVIRONMENT = 'DEV'
export const PLATFORM_URL = 'https://dev.cryptolot.club'
export const COMMON_LINK = `${PLATFORM_URL}/api/v1/agents/players/games/url`
export const CREATE_USER_LINK = `${PLATFORM_URL}/api/v1/agents/players/create`
export const CREATE_COINS_VALUE = `${PLATFORM_URL}/api/v1/agents/players/accounts/transfers/in/perform`
export const TENANT_ID = 'as_platform'
export const PRESHARED_KEY = '6qyqrl05jbv3a7jo7l0s33yx'
export const childProductId = setChildProductId()

export const staticGames = [
    { gameName: 'domino', link: 'https://dev.cryptolot.club/webgl-games/domino' },
    { gameName: 'gin-rummy', link: 'https://dev.cryptolot.club/webgl-games/gin-rummy' },
    { gameName: 'crazy8s', link: 'https://dev.cryptolot.club/webgl-games/crazy8s' },
    { gameName: 'snakes-and-ladders', link: 'https://dev.cryptolot.club/webgl-games/snakes-and-ladders' },
]

interface ILANGUAGE {
    [key: string]: string
}

export const LANGUAGES: ILANGUAGE = {
    en: 'English',
    ua: 'Українська',
    fr: 'Français',
    cn: '中国人',
    jp: '日本語',
}

export const tutorials: any = {
    moon: HowToPlayMoon,
    mines: HowToPlayMines,
    balloon: HowToPlayBalloon,
    dice: HowToPlayDice,
    coinflip: HowToPlayCoinflip,
    plinko: HowToPlayPlinko,
    hilo: HowToPlayHilo,
    roulette: HowToPlayRoulette,
    // prediction_btc: HowToPlayPredictBTC,
    // prediction_eth: HowToPlayPredictETH,
    domino: HowToPlayDomino,
    'gin-rummy': HowToPlayGinRummy,
    crazy8s: HowToPlayCrazy8s,
    'snakes-and-ladders': HowToPlaySnakeLadders,
}

export interface Ticket {
    id: number
    amount: number
    price: number
    payout: number
    status: string
    currency: string
    earn: {
        currency: string
        value: number
    }
    gameName: string
    gameId: number
    date: string
}
