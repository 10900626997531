<template>
    <div class="tabs-box">
        <wallet-modal-tabs-item v-for="tab in tabs" :key="tab" :title="tab" />
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import WalletModalTabsItem from './WalletModalTabsItem.vue'
const { t } = useI18n()

const tabs = [
    t("wallet-modal.tabs.deposit"),
    t("wallet-modal.tabs.withdraw"),
    t("wallet-modal.tabs.transactions")
]
</script>

<style scoped>
.tabs-box {
    display: flex;
    column-gap: 15px;
    align-items: center;
}
</style>