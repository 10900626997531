<template>
    <div class="table-wrapper">
        <div v-if="!isLoaded" class="loading">loading...</div>
        <div class="table-box" v-else>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">{{ $t('wallet-modal.transactions.table.date-time') }}</th>
                        <th scope="col">{{ $t('wallet-modal.transactions.table.amount') }}</th>
                        <th scope="col">{{ $t('wallet-modal.transactions.table.type') }}</th>
                        <th scope="col">{{ $t('wallet-modal.transactions.table.status') }}</th>
                        <th scope="col" class="hash-header">{{ $t('wallet-modal.transactions.table.transactions-hash')
                            }}
                        </th>
                    </tr>
                </thead>
                <tbody v-if="paginatedData.length">
                    <tr v-for="historyEl in paginatedData" :key="historyEl">
                        <td class="date">{{ getDate(historyEl.metadata.blockTimestamp) }}</td>
                        <td class="amount">
                            <span>{{ historyEl.value === null ? "USDT" : $t('matic') }}</span><span
                                class="currency-value">
                                {{ historyEl.value || 0 }}
                            </span>
                        </td>
                        <td class="type">{{ getStatus(historyEl) }}</td>
                        <td class="status">complete</td>
                        <td class="hash-td">
                            <a class="hash" :href="`https://www.oklink.com/amoy/tx/${historyEl.hash}`"
                                target="_blank">
                                {{ historyEl.hash }}
                            </a>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr class="no-data-tr">
                        <td :colspan="5" class="no-data">{{ $t('wallet-modal.transactions.no-data') }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="row">
                <div class="row" v-show="history.length > 5">
                    <button class="nav-btn" @click="backPage">{{ $t('prev') }}</button>
                    <button class="nav-btn" :class="{ active: page === item }"
                        v-for="item in Math.ceil(data.length / perPage)" :key="item" @click="() => goToPage(item)">
                        {{ item }}
                    </button>
                    <button class="nav-btn" @click="nextPage">{{ $t('next') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/stores/authStore'
import handlePagination from '@/helpers/handlePagination'

const { getTransactionHistory, getAccounts } = useAuthStore()

const { t } = useI18n()

const history = ref([])
const walletAddress = ref('')
const isLoaded = ref(false)

const { paginatedData, data, perPage, nextPage, backPage, goToPage, page } = handlePagination(history, 5)

// const tableColumnsHeader = [
//     t('wallet-modal.transactions.table.date-time'),
//     t('wallet-modal.transactions.table.amount'),
//     t('wallet-modal.transactions.table.type'),
//     t('wallet-modal.transactions.table.status'),
//     t('wallet-modal.transactions.table.transactions-hash'),
// ]

const getDate = (dateString) => {
    const dateObject = new Date(dateString)

    // Format the date object to desired format
    const formattedDate = `${("0" + dateObject.getUTCHours()).slice(-2)}:${("0" + dateObject.getUTCMinutes()).slice(-2)} ${("0" + dateObject.getUTCDate()).slice(-2)}.${("0" + (dateObject.getUTCMonth() + 1)).slice(-2)}.${dateObject.getUTCFullYear()}`
    console.log(formattedDate)

    return formattedDate
}

const getStatus = (history) => {
    if (history.category === 'erc20') {
        return "Deposit"
    }
    if (!history.value) {
        return "Round fee"
    }

    if (history.from === walletAddress.value.toLowerCase()) {
        return 'Withdraw'
    }

    if (history.to === walletAddress.value.toLowerCase()) {
        return "Deposit"
    }
}

onMounted(async () => {
    walletAddress.value = (await getAccounts())[0]
    history.value = await getTransactionHistory()
    isLoaded.value = true
    console.log(history.value)
})

</script>

<style scoped>
.row {
    justify-content: center;
}

.loading {
    font-size: clamp(16px, 2rem, 36px);
    color: #fff;
    height: 100%;
    text-align: center;
    width: 100%;
}

.table-wrapper {
    overflow-x: auto;
    max-width: 100%;
    height: 100%;
    padding-bottom: 1.5rem;
    width: 100%;

    &::-webkit-scrollbar {
        width: 1px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1b274d;
    }

    .table {
        border-collapse: collapse;
        position: relative;
        table-layout: fixed;
        width: 800px;
        margin-bottom: 1rem;
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.4);
        border-left: 0.5px solid rgba(0, 0, 0, 0.4);
        border-right: 0.5px solid rgba(0, 0, 0, 0.4);

        thead th {
            font-family: "Roboto", sans-serif;
            padding: 15px;
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
            color: #bec9f4;
            background: #171f44;
            white-space: nowrap;

        }

        tbody {
            tr {
                transition: all 0.3s ease-in;
            }

            td {
                text-align: center;
                padding: 15px 5px;
                font-size: 14px;
                font-weight: 700;
                color: #a1aed4;
                white-space: nowrap;
            }
        }
    }
}

.table-box {
    width: 100%;
}

@media screen and (min-width: 786px) {
    .table-wrapper {
        overflow-x: inherit;
        width: 100%;

        .table {
            width: 100%;
        }
    }
}

.hash-header {
    width: 240px;
}

.hash {
    display: inline-block;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #BEC9F4;
}

.status {
    color: #6bc874 !important;
}

.currency-value {
    color: #ffc656;
    margin-left: 4px;
}

.hash {
    color: #BEC9F4;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:visited {
        color: #BEC9F4;
    }
}

.no-data-tr {
    width: 100%;
    text-align: center;
}

.no-data {
    margin: 3rem auto !important;
    font-weight: 600;
    font-size: 32px;
    color: #bec9f4;
    width: 100%;
    display: flex;
    font-size: 32px !important;
    justify-content: center;
    align-items: center;
    transform: translateX(220%);
    font-family: "Roboto", sans-serif;
    opacity: 0.7;
}
</style>
