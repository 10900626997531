<template>
    <div class="withdraw-box">
        <header class="header">
            <div class="left-side" :class="{ error: isAmountValid }"><img src="@/assets/images/matic-logo.png"
                    class="currency-coin" alt="usdt-coin"><span>{{
                        $t('wallet-modal.withdraw.enter') }} </span> <span class="usdt-coin">{{
        $t('matic') }}</span>:
                <input type="number" v-model="amountCoinsInput"/>
            </div>
            <div class="right-side" :class="{ error: isAddressValid }">
                <input type="text" v-model="addressInput" :placeholder="$t('wallet-modal.withdraw.placeholder')">
            </div>
        </header>
        <div v-if="!isSent" class="inner-content">
            <p class="text-desc">
                <span>{{ $t('notice') }}:</span>
                {{ $t('wallet-modal.withdraw.desc') }}
            </p>
            <button class="btn btn-red" @click="onWithdraw">{{ $t('wallet-modal.withdraw.withdraw') }}</button>
        </div>
        <div v-else class="transferred">
            <div v-if="!isTransferred" class="loading">transferring...</div>
            <div v-else class="result">
                <p>{{ $t('wallet-modal.withdraw.withdrawal-complete') }}</p>
                <div class="header">
                    <div class="left-side"><span>{{ $t('transaction-hash') }}:</span>
                    </div>
                    <div class="right-side">
                        <span class="hash">{{ hash }}</span>
                    </div>
                </div>
            </div>
        </div>
        <wallet-modal-tab-footer />
    </div>
</template>

<script setup>
import { ref } from 'vue'

import WalletModalTabFooter from './WalletModalTabFooter/WalletModalTabFooter.vue'
import { useAuthStore } from '@/stores/authStore'

const { isValidAddress, sendTransaction } = useAuthStore()

const amountCoinsInput = ref('0')
const addressInput = ref('')
const hash = ref('')
const isSent = ref(false)
const isTransferred = ref(false)
const isAddressValid = ref(false)
const isAmountValid = ref(false)

const onWithdraw = async () => {
    if (Number.parseFloat(amountCoinsInput.value) && isValidAddress(addressInput.value)) {
        isAddressValid.value = false
        isAmountValid.value = false
        isSent.value = true
        const receipt = await sendTransaction(addressInput.value, amountCoinsInput.value)
        hash.value = receipt.hash
        isTransferred.value = true

        return
    }

    isAddressValid.value = true
    isAmountValid.value = true
}
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .left-side {
        padding: 10px 15px;
        position: relative;
        width: 40%;
        display: flex;
        background-color: #212F72;

        span {
            line-height: normal;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-family: "Rubik", sans-serif;
            font-weight: 700;
            font-size: 18px;
            color: #bec9f4;
        }

        input {
            background-color: #212F72;
            border: 0;
            padding-left: 4px;
            width: 100%;
            outline: none;
            color: #ffc656;
            font-family: "Rubik", sans-serif;
            font-weight: 700;
            font-size: 18px;
        }

        input:focus {
            background-color: #212F72 !important;
            border: 0 !important;
        }
    }

    .error {
        &::after {
            visibility: unset;
        }
    }

    .left-side::after {
        content: 'Wrong amount!';
        position: absolute;
        transition: all .3s ease;
        visibility: hidden;
        top: 105%;
        left: 30%;
        color: #FF5656;
    }

    .right-side {
        width: 60%;
        position: relative;
        background-color: #171F44;

        input {
            padding: 14px 15px 13px 15px;
            outline: none;
            background-color: #171F44;
            border: 0;
            font-family: "Rubik", sans-serif;
            display: block;
            width: 100%;
            height: 100%;
            font-weight: 500;
            font-size: 18px;
            color: #f4f7ff;

        }

        input:focus {
            background-color: #171f44 !important;
            border: 0 !important;
        }

        input::placeholder {
            color: #bec9f4;
        }
    }

    .right-side::after {
        content: 'Invalid wallet address!';
        visibility: hidden;
        transition: all .3s ease;
        position: absolute;
        top: 105%;
        left: 30%;
        color: #FF5656;
    }

}

.currency-coin {
    width: 28px;
    height: 28px;
    margin-right: 4px;
}

.usdt-coin {
    margin-left: 4px;
    text-transform: uppercase;
    margin-right: 2px;
}

.inner-content {
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-desc {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 140%;
    width: 70%;
    letter-spacing: 0.02em;
    color: #6b7fc8;

    span {
        font-weight: 600;
        text-transform: uppercase;
    }
}

.btn {
    margin-top: 0;
}

.loading {
    width: 100%;
    text-align: center;
    font-size: clamp(16px, 5vw, 34px);
    color: #fff;
}

.result {
    display: flex;
    flex-direction: column;

    .header {
        margin-bottom: 0;
    }

    p {
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #ffc656;
        margin-bottom: 20px;
    }

    .left-side {
        font-size: 18px;
        padding: 13px 15px 12px 15px;
        text-transform: uppercase;
        color: #bec9f4;

        span {
            font-weight: 500;
        }
    }

    .right-side {
        height: 100%;
        padding: 10px 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;


        .hash {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
</style>