<template>
  <section class="wrapper">
    <div class="container">
      <div class="row">
        <div class="title-wrapper">
          <div>
            <h5 class="subtitle">{{ $t('contacts.contacts-banner.subtitle') }}</h5>
            <h2 class="title">{{ $t('contacts.contacts-banner.title') }}</h2>
            <p class="text">
              {{ $t('contacts.contacts-banner.text') }}
            </p>
          </div>
        </div>
        <div class="img-box">
          <img src="@/assets/images/contacts/contact.png" alt="contacts" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.wrapper {
  background: #070b28;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}

.row {
  flex-direction: column-reverse;
  align-items: center;
}

.title-wrapper {
  text-align: center;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  align-self: center;
}

.img-box {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  img {
    margin-left: auto;
    max-width: 90%;
    width: 100%;
    margin-bottom: 1rem;
  }
}

.text {
  text-align: start;
  margin-bottom: 0;
}

@media screen and (min-width: 576px) {
  .img-box {
    img {
      display: block;
    }
  }
}

@media screen and (min-width: 786px) {
  .row {
    flex-direction: row;
  }

  .title-wrapper {
    text-align: start;
  }

  .text {
    margin-bottom: 2.5rem;
  }


  .img-box {
    img {
      max-width: 61%;
      width: 100%;
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: 992px) {

  .title-wrapper {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .img-box {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

@media screen and (min-width: 1200px) {}
</style>
