import { defineStore } from 'pinia'
import { ref } from 'vue'

interface LinkData {
    date: string
    id: number
    name: string
    country: string
    city: string
    affiliatesLink: string
    action: string
    bet: string
}

export default defineStore('affiliatesStore', () => {
    const links = ref<any[]>([])
    const saveAffiliateLink = (link: LinkData) => {
        links.value.push(link)
    }

    return {
        links,
        saveAffiliateLink,
    }
})
