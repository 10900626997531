import { defineStore } from 'pinia'
import { CHAIN_NAMESPACES, SafeEventEmitterProvider, WALLET_ADAPTERS } from '@web3auth/base'
import { Web3AuthNoModal } from '@web3auth/no-modal'
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider'
import { OpenloginAdapter } from '@web3auth/openlogin-adapter'
import { Web3 } from 'web3'
import {isAddress} from 'web3-validator'
import { onMounted, reactive, ref } from 'vue'
import { ethers } from 'ethers'
import { Alchemy, Network, AlchemySubscription, AssetTransfersCategory } from 'alchemy-sdk'
import { MetamaskAdapter } from '@web3auth/metamask-adapter'

export const useAuthStore = defineStore('auth', () => {
    const user = reactive<{ authenticated: boolean; name: string | null; avatar: string | null }>({
        authenticated: false,
        name: null,
        avatar: null
    })
    const provider = ref<SafeEventEmitterProvider | any>(false)
    const balance = ref(0.00)

    const clientId = 'BAc3yXhNHuYEBvXyeWcpIpGy-o9te-RcVbPAqGSf1WokhA1w7x68dVc5HQaNrvz54EMsFGirRBCmSwyyKWp7oYY' // get from https://dashboard.web3auth.io

    const chainConfig = {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: '0x13882',
        rpcTarget: 'https://80002.rpc.thirdweb.com',
        displayName: 'Polygon Amoy Testnet',
        blockExplorer: 'https://www.oklink.com/amoy/',
        ticker: 'MATIC',
        tickerName: 'Matic',
    }

    const config = {
        apiKey: 'kaVZ44N7pd_NlLzH1vS2wsBXnz3DetrF',
        network: Network.MATIC_AMOY,
    }
    const alchemy = new Alchemy(config)

    alchemy.ws.on(
        {
            method: AlchemySubscription.PENDING_TRANSACTIONS,
        },
        async (tx) => {
            balance.value = await getBalance() as number
        }
    )

    const web3auth = new Web3AuthNoModal({
        clientId,
        chainConfig,
        web3AuthNetwork: 'sapphire_devnet',
    })

    const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } })
    const openloginAdapter = new OpenloginAdapter({
        privateKeyProvider: privateKeyProvider,
    })
    web3auth.configureAdapter(openloginAdapter)

    const metamaskAdapter = new MetamaskAdapter({
        clientId,
        sessionTime: 3600, // 1 hour in seconds
    })
    metamaskAdapter.setAdapterSettings({
        clientId,
        sessionTime: 86400, 
    })

    // it will add/update  the metamask adapter in to web3auth class
    web3auth.configureAdapter(metamaskAdapter)

    onMounted(async () => {
        await web3auth.init()
        provider.value = web3auth.provider

        if (web3auth.connected) {
            const userInfo = await web3auth.getUserInfo()

            user.authenticated = true
            user.name = userInfo.name as string
            user.avatar = userInfo.profileImage as string
        }
    })

    const performLogIn = async (loginProvider: string) => {
        if (loginProvider === 'metamask') {
            provider.value = await web3auth.connectTo(WALLET_ADAPTERS.METAMASK, {
                loginProvider: loginProvider
            })
        } else { 
            provider.value = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
                loginProvider: loginProvider,
            })
        }
        if (web3auth.connected) {
            const userInfo = await web3auth.getUserInfo()

            user.authenticated = true
            user.name = userInfo.name as string
            user.avatar = userInfo.profileImage as string
        }
    }

    const performLogOut = async () => {
        await web3auth.logout()
        provider.value = null
        user.authenticated = false
    }

    const getAccounts = async () => {
        if (!provider.value) {
            console.log("provider not initialized yet")

            return
        }
        const web3 = new Web3(provider.value as any)

        // Get user's Ethereum public address
        const address = await web3.eth.getAccounts()

        return address
    }

    const getBalance = async () => {
        if (!provider.value) {
            console.log('provider not initialized yet')

            return
        }

        try {
            const ethersProvider = new ethers.BrowserProvider(provider.value as any)

            const signer = await ethersProvider.getSigner()

            const address = signer.getAddress()

            const userBalance = ethers.formatUnits(
                await ethersProvider.getBalance(address), // Balance is in wei
                'ether'
            )

            return Number.parseFloat(userBalance)
        } catch (error) {
            console.log(error)
        }
    }

    const getTransactionHistory = async () => {

        try {
            const walletAddress = (await getAccounts())![0]
            const inHistory = await alchemy.core.getAssetTransfers({
                fromBlock: '0x0',
                toBlock: 'latest',
                excludeZeroValue: false,
                toAddress: walletAddress,
                withMetadata: true,
                category: [
                    AssetTransfersCategory.EXTERNAL,
                    AssetTransfersCategory.ERC20,
                    AssetTransfersCategory.ERC721,
                    AssetTransfersCategory.ERC1155,
                ],
            })

            const outHistory = await alchemy.core.getAssetTransfers({
                fromBlock: '0x0',
                toBlock: 'latest',
                excludeZeroValue: false,
                withMetadata: true,
                fromAddress: walletAddress,
                category: [
                    AssetTransfersCategory.EXTERNAL,
                    AssetTransfersCategory.ERC20,
                    AssetTransfersCategory.ERC721,
                    AssetTransfersCategory.ERC1155,
                ],
            })

            const history = [...inHistory.transfers, ...outHistory.transfers].toSorted(
                (a, b) => new Date(b.metadata.blockTimestamp) as any - (new Date(a.metadata.blockTimestamp) as any)
            )

            return history
        } catch (error) {
            console.log(error)
            alert('failed to fetch data,')
        }
    }

    const isValidAddress = (address:string) => {

        return isAddress(address)
    }

    const sendTransaction = async (destination:string, value:string) => {
        const ether = new ethers.BrowserProvider(provider as any)

        try {
            const feeData = await ether.getFeeData()
            const signer = await ether.getSigner()
            const amount = ethers.parseEther(value)

            const tx = await signer.sendTransaction({
                to: destination,
                value: amount,
                maxPriorityFeePerGas: feeData.maxPriorityFeePerGas, // Max priority fee per gas
                maxFeePerGas: feeData.maxFeePerGas, // Max fee per gas
            })

            const receipt = await tx.wait()

            return receipt
        } catch (error) {
            console.log(error)

            alert(
                'failed to resolve'
            )
        }
    }

    return {
        getTransactionHistory,
        isValidAddress,
        sendTransaction,
        user,
        balance,
        performLogIn,
        performLogOut,
        getAccounts,
        getBalance  
    }
})
