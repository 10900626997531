import { defineStore } from 'pinia'
import { shallowRef } from 'vue'
// eslint-disable-next-line vue/prefer-import-from-vue
import { extend } from '@vue/shared'

const component = extend({})

type VueComponent = InstanceType<typeof component>

interface StoreProps {
    component: null | VueComponent
    props?: object
}

const defaultState = { component: null, props: {} }

export default defineStore('modal-store', () => {
    const modalState = shallowRef<StoreProps>(defaultState)

    const onOpenModal = (payload: any) => {
        const { props, component } = payload

        modalState.value = { component, props: props || {} }
    }

    const onCloseModal = () => {
        modalState.value = defaultState
    }

    return {
        onCloseModal,
        onOpenModal,
        modalState
    }
})
