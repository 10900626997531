import { Ticket } from '@/constants'
import useTicketsStore from '@/stores/ticketsStore'
import { storeToRefs } from 'pinia'

const THIRTY_SECONDS: number = 30000
const ONE_MINUTE: number = 60000
const TWO_MINUTES: number = 120000
const ONE_DAY: number = 1000 * 60 * 60 * 24
const ONE_WEEK: number = 1000 * 60 * 60 * 24 * 7
const ONE_MONTH: number = 1000 * 60 * 60 * 24 * 30

abstract class AbstractLottery {
    public endTime: string
    public duration: string
    public id: number
    public ticketPrice: number = 1
    public jackpot: number = 27
    public currency: string = 'AWARD'
    public participants: any[]
    public tickets: any[] = []
    public winTicket!: Ticket
    public status: 'STARTED' | 'ENDED' | 'PROCESSING' = 'STARTED'

    public constructor(endDate: string, id: number, duration: string, participants: any[] = []) {
        this.endTime = endDate
        this.duration = duration
        this.id = id
        this.participants = participants
    }

    public abstract pickWinner(): any
}

export class Lottery extends AbstractLottery {
    public pickWinner(): number {
        const { tickets } = storeToRefs(useTicketsStore())
        if (this.tickets.length) {
            this.winTicket = this.tickets[Math.floor(Math.random() * this.tickets.length)]
            this.winTicket.payout = 27
        }

        if (!this.participants.length) {
            return 0
        }

        for (let i = 0; i < tickets.value.length; i++) {
            if (tickets.value[i].gameId === this.id) {
                if (tickets.value[i].id === this.winTicket.id) {
                    tickets.value[i].payout = 27
                    tickets.value[i].status = 'completed'
                    tickets.value[i].earn.value = 11.5
                } else {
                    tickets.value[i].status = 'completed'
                }
            }
        }

        return Math.floor(Math.random() * 2)
    }
}

abstract class AbstractLotteryFactory {
    public abstract createLottery(duration: string, id: number): Lottery
}

export class LotteryFactory extends AbstractLotteryFactory {
    createLottery(duration: string, id: number): Lottery {
        const date = new Date()
        let lottery
        switch (duration) {
            case '15m':
                lottery = new Lottery(new Date(date.getTime() + THIRTY_SECONDS).toISOString(), id, duration, [])
                break
            case '30m':
                lottery = new Lottery(new Date(date.getTime() + ONE_MINUTE).toISOString(), id, duration, [])
                break
            case '1h':
                lottery = new Lottery(new Date(date.getTime() + TWO_MINUTES).toISOString(), id, duration, [])
                break
            case '1d':
                lottery = new Lottery(new Date(date.getTime() + ONE_DAY).toISOString(), id, duration, [])
                break
            case '1w':
                lottery = new Lottery(new Date(date.getTime() + ONE_WEEK).toISOString(), id, duration, [])
                break
            case '1m':
                lottery = new Lottery(new Date(date.getTime() + ONE_MONTH).toISOString(), id, duration, [])
                break
        }

        return lottery as Lottery
    }
}
