import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { serverController } from '@/helpers/demoLottery/ServerModule'
import { randomNumber } from '@/helpers/randomNumber'
import { Ticket } from '@/constants'

export default defineStore('userTickets', () => {
    const tickets = useStorage('tickets', [] as Ticket[])
    let initialTicketId = randomNumber(5)

    const buyTicket = async (
        amount: number,
        price: number,
        payout: number = 0,
        status: string,
        gameName: string,
        gameId: number,
        date: string,
        userId: string
    ) => {
        const result = await serverController.getLottery(gameId)
        result.participants.push(userId)
        for (let i = 0; i < amount; i++) {
            const ticket = {
                id: initialTicketId,
                amount: 1,
                price: 1,
                gameName,
                gameId,
                payout: 0,
                earn: {
                    currency: 'AWARD',
                    value: 0,
                },
                status,
                date,
                currency: 'USDT',
            }
            initialTicketId = ++initialTicketId
            tickets.value.unshift(ticket)
            result.tickets.unshift(ticket)
        }
    }

    return {
        tickets,
        buyTicket,
    }
})
