<template>
    <div class="modal-box">
        <header class="modal-header"><span class="header-title">{{
            $t('wallet') }}</span>
            <button class="close-btn" @click="onCloseModal"><i class="fa-solid fa-xmark"></i></button>
        </header>
        <div class="content">
            <div class="tabs-container">
                <wallet-modal-tabs />
                <div class="balance-box">
                    <p><span>{{ $t('wallet-modal.balance') }}</span> <span class="currency">{{ $t('matic') }}</span>
                        <span class="value">{{ balance }}</span>
                    </p>
                </div>
            </div>
            <div class="inner-content">
                <transition name="fade" mode="out-in">
                    <component :is="tabsContent[currentTab]" :key="currentTab"></component>
                </transition>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores/authStore'
import useModalStore from '@/stores/useModalStore'
import WalletModalTabs from './WalletModalTabs/WalletModalTabs.vue'
import WalletModalTabDeposit from './WalletModalTabs/WalletModalTabsContent/WalletModalTabDeposit.vue'
import WalletModalTabWithdraw from './WalletModalTabs/WalletModalTabsContent/WalletModalTabWithdraw.vue'
import WalletModalTabTransactions from './WalletModalTabs/WalletModalTabsContent/WalletModalTabTransactions.vue'


const { t } = useI18n()

const currentTab = ref(t("wallet-modal.tabs.deposit"))

function onChangeTab(newTab) {
    currentTab.value = newTab
}

provide('tabs-control', {
    currentTab, onChangeTab
})

const { balance } = storeToRefs(useAuthStore())

const { onCloseModal } = useModalStore()

const tabsContent = {
    [t("wallet-modal.tabs.deposit")]: WalletModalTabDeposit,
    [t("wallet-modal.tabs.withdraw")]: WalletModalTabWithdraw,
    [t("wallet-modal.tabs.transactions")]: WalletModalTabTransactions,
}
</script>

<style scoped>
.modal-box {
    max-width: 900px;
    width: 100%;
    border-bottom: 3px solid #171f44;
}

.content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: #070B28;
    max-width: 900px;
}

.inner-content {
    width: 100%;
}

.close-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(2);
    background-color: transparent;
    left: 91%;
    top: 40%;
    border: 0;
    outline: none;
    cursor: pointer;

    & i {
        color: #fff;
    }
}

.modal-header {
    position: relative;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #171F44;
    padding: 16px;
    width: 100%;
}


.header-title {
    color: #DAE1FC;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.tabs-container {
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 15px;
}

.balance-box {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #bec9f4;
}

.currency {
    text-transform: uppercase;
}

.value {
    color: #ffc656;
    margin-left: 4px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-move {
    transition: transform 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

@media screen and (min-width: 992px) {
    .close-btn {
        left: 93%;
    }
}

@media screen and (min-width: 1300px) {
    .close-btn {
        left: 95%;
    }
}
</style>