<template>
    <div class="tab" @click="onChangeTab(title)" :class="{ active: currentTab === title }">
        <span class="tab-title">{{ title }}</span>
    </div>
</template>

<script setup>
import { inject } from 'vue'

defineProps({
    title: String
})

const { currentTab, onChangeTab } = inject('tabs-control')

</script>

<style scoped>
.tab {
    background: rgba(33, 47, 114, 0.35);
    padding: 10px 30px;
    transition: background-color .3s ease;
    cursor: pointer;
}

.tab:hover {
    background-color: #212f72;

    & .tab-title {
        color: #bec9f4;
    }
}

.active {
    background-color: #212f72;

    & .tab-title {
        color: #bec9f4;
    }
}

.tab-title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #a0a6c5;
    transition: color .3s ease;
}
</style>