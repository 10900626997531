<template>
    <section class="wrapper">
        <div class="container">
            <div class="row">
                <div class="content">
                    <img src="@/assets/images/404.png" alt="404">
                    <div>
                        <h4 class="title">Oops, something went wrong!</h4>
                        <router-link to="/"><button class="btn"><i class="fas fa-angle-double-left"></i>back to
                                home</button></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'NotFoundPage',
}
</script>

<style lang="scss" scoped>
.wrapper {
    height: 100%;
    min-height: 100vh;
    background-image: url(@/assets/images/404-bg.png);
    display: flex;
    align-items: center;

    img {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        margin-bottom: 3rem;
    }
}

.row {
    justify-content: center;
}

.content {
    text-align: center;

    i {
        margin-right: .5rem;
    }
}

.title {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    color: #dfe3ff;
}

@media screen and (min-width: 576px) {
    .title {
        font-size: 36px;
        line-height: 46px;
    }
}
</style>
