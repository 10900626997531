<template>
    <footer>
        <p class="footer-text">{{ $t('wallet-modal.footer.text') }} <router-link to="/terms-of-service">{{
            $t('terms') }}</router-link> <span>{{ $t('and')
                }}</span> <router-link to="/privacy-policy">{{
            $t('privacy') }}</router-link></p>
    </footer>
</template>

<script setup>
</script>

<style scoped>
.footer-text {
    margin-top: 16px;
    text-align: center;
    padding: 10px 10px 0 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: clamp(16px, 1.5vw, 18px);
    text-align: center;
    line-height: 24px;
    color: #44507a;

    a {
        color: #8699d1;
    }
}
</style>