
import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

export default defineStore('user-settings', () => {
    const defaultAvatar:string = '/settings/default-avatar.png'
    const currentAvatar = useStorage("avatar", defaultAvatar, sessionStorage)
    const currentNickname = useStorage('nickname', "")

    const onChangeAvatar = (avatarImg: string): void => {
        currentAvatar.value = avatarImg
    }

    const onChangeNickname = (nickname: string): void => {
        currentNickname.value = nickname
    }

    return {
        currentAvatar,
        currentNickname,
        defaultAvatar,
        onChangeAvatar,
        onChangeNickname
    }
})