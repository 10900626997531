import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import GamesCatalogView from '@/views/GamesCatalogView.vue'
import GameDetailsView from '@/views/GameDetailsView.vue'
import WinnersView from '@/views/WinnersView.vue'
import ContactsView from '@/views/ContactsView.vue'
import NotFoundPage from '@/views/NotFoundPage.vue'
import LotteryCatalogView from '@/views/LotteryCatalogView.vue'
import LotteryDetailsView from '@/views/LotteryDetailsView.vue'
import AppPrivacyPolicy from '@/views/static/AppPrivacyPolicy.vue'
import AppTermsService from '@/views/static/AppTermsService.vue'
import AffiliateView from '@/views/AffiliateView.vue'
import AffiliatesView from '@/views/AffiliatesView.vue'
import AppRiskWarning from '@/views/static/AppRiskWarning.vue'
import AppQuickStartPage from '@/views/static/AppQuickStartPage.vue'
import FAQView from '@/views/FAQView.vue'
import BetHistoryView from '@/views/BetHistoryView.vue'
import Settings from '@/views/SettingsView.vue'
import Wallet from '@/components/common/topmenu/profile/TopMenuWallet.vue'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
        },
        {
            path: '/about',
            name: 'about',
            component: AboutView,
        },
        {
            path: '/lottery',
            name: 'lottery',
            component: LotteryCatalogView,
        },
        {
            path: '/lottery/:id',
            name: 'lottery-details',
            component: LotteryDetailsView,
        },
        // {
        //     path: '/games',
        //     name: 'games',
        //     component: GamesCatalogView,
        // },
        // {
        //     path: '/games/:gameId',
        //     name: 'game-details',
        //     component: GameDetailsView,
        // },
        {
            path: '/winners',
            name: 'winners',
            component: WinnersView,
        },
        {
            path: '/bet-history',
            name: 'bet-history',
            component: BetHistoryView,
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
        },
        {
            path: '/wallet',
            name: 'wallet',
            component: Wallet,
        },
        {
            path: '/contacts',
            name: 'contacts',
            component: ContactsView,
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: AppPrivacyPolicy,
        },
        {
            path: '/terms-of-service',
            name: 'terms-of-service',
            component: AppTermsService,
        },
        {
            path: '/affiliate',
            name: 'affiliate-program',
            component: AffiliateView,
        },
        {
            path: '/affiliates',
            name: 'affiliates',
            component: AffiliatesView,
        },
        {
            path: '/risk-warning',
            name: 'risk-warning',
            component: AppRiskWarning,
        },
        {
            path: '/quickstart',
            name: 'quick-start-guide',
            component: AppQuickStartPage,
        },
        {
            path: '/faq',
            name: 'faq',
            component: FAQView,
        },
        {
            path: '/:catchAll(.*)*',
            name: 'NotFoundPage',
            component: NotFoundPage,
        },
    ],
})

export default router
