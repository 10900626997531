<template>
    <div class="box">
        <div class="content">
            <div class="link-box">
                <h3 class="title">{{ $t('affiliates.top-content.faq-title') }}</h3>
                <button class="btn"><router-link :to="`/${$t('affiliates.top-content.link')}`">{{
                    $t('affiliates.top-content.read-more') }}</router-link></button>
            </div>
            <img class="image" src="@/assets/images/affiliates/affilate.png" alt="friends">
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.box {
    border: 2px solid #3b4363;
    background-color: #0b122e;
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 30px;
}

.link-box {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
}

.title {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 0;
}

.btn {
    background-color: #2154c7;
    background-image: none;
    font-size: 14px;
    padding: 10px 15px;

    a {
        color: #fff;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.btn:hover {
    background-image: none;
    background-color: #1b5ff8;
}

.image {
    object-fit: contain;
}

@media screen and (min-width: 576px) {
    .title {
        font-size: 22px;
    }

    .btn {
        font-size: 16px;

    }
}

@media screen and (min-width: 786px) {
    .title {
        font-size: 24px;
    }

    .btn {
        padding: 15px 30px;
    }
}

@media screen and (min-width: 992px) {
    .title {
        font-size: 26px;
    }
}

@media screen and (min-width: 1200px) {
    .title {
        font-size: 32px;
    }
}</style>