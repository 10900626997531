<template>
    <div v-if="isModalOpen && !user.authenticated" class="modal-mask">
        <div class="modal-container" ref="target">
            <header class="modal-header"><img src="@/assets/images/login.svg" alt="entry"><span class="header-title">{{
        $t('sign-in') }} / {{ $t('login-modal.sign-up') }}</span></header>
            <div class="modal-content">
                <h3 class="modal-title">{{ $t('login-modal.title') }}</h3>
                <button class="login-btn" @click.stop="signIn('google')"><img src="@/assets/images/google-icon.png"
                        alt="google">{{
        $t('login-modal.google-account')
    }}</button>
                <button class="login-btn" @click.stop="signIn('facebook')"><img src="@/assets/images/facebook-icon.png"
                        alt="facebook">{{
        $t('login-modal.facebook-account')
    }}</button>
                <button class="login-btn" @click.stop="signIn('metamask')"><img src="@/assets/images/metamask-icon.png"
                        alt="metamask">{{
        $t('login-modal.metamask') }}</button>
                <p class="agreement-text">{{ $t('login-modal.agreement-text') }} <router-link to="/privacy-policy">{{
        $t('privacy-policy.title') }}</router-link> {{ $t('and') }} <router-link
                        to="/terms-of-service">{{
                        $t('terms') }}</router-link></p>
            </div>
            <div @click="closeModal" class="close-modal-btn"></div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'

import { onClickOutside } from '@vueuse/core'

import { useAuthStore } from '@/stores/authStore'
const { user, performLogIn } = useAuthStore()

const emit = defineEmits(['modal-closed', 'user-signed-in'])
const props = defineProps({
    isOpen: Boolean,
})

const isModalOpen = ref(props.isOpen)
watch(
    () => props.isOpen,
    (newValue) => {
        isModalOpen.value = newValue
    }
)

const target = ref(null)

onClickOutside(target, () => emit('modal-closed'))

const signIn = async (loginProvider) => {
    await performLogIn(loginProvider)
}

const closeModal = () => {
    isModalOpen.value = false
    emit('modal-closed')
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
    position: relative;
    max-width: 400px;
    width: 100%;
    /* margin: auto auto; */
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #171F44;
    padding: 16px;

    & img {
        margin-right: 16px;
    }
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 20px 0;
    background-color: #070B28;
    border-bottom: 3px solid #171F44;
}

.modal-title {
    color: #DAE1FC;
    margin-bottom: 30px;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 500;
    line-height: normal;
}

.header-title {
    color: #DAE1FC;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 400;
    line-height: normal;
}

.login-btn {
    display: flex;
    align-items: center;
    column-gap: 20px;
    border: none;
    width: 70%;
    margin-bottom: 20px;
    padding: 8px 0 8px 20px;
    background-color: #171F44;
    cursor: pointer;
    color: #9FACD2;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: all .3s ease;

    img {
        width: 36px;
        height: 36px;
    }
}

.login-btn:hover {
    background-color: #2E3B70;
    color: #DAE1FC;
}

.agreement-text {
    padding: 0 20px;
    color: #44507A;
    letter-spacing: 2px;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    text-transform: initial;

    & a {
        color: #8699D1;
    }
}

.close-modal-btn {
    position: absolute;
    left: calc(100% + 4px);
    background-image: url('@/assets/images/close-btn.png');
    top: -34px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: all .3s ease;
}

.close-modal-btn:hover {
    transform: scale(1.09);
    background-image: url('@/assets/images/close-light.png');
}
</style>