import { randomNumber } from '../randomNumber'
import { LotteryFactory, Lottery } from './LotteryFactory'
const lotteryDurations: string[] = ['15m', '30m', '1h', '1d', '1w', '1m']

class ServerModuleController {
    public lotteryFactory: LotteryFactory
    public lotteries: Lottery[] = []
    public endedLotteries: Lottery[] = []
    private lotteryId: number = randomNumber(5)

    public constructor(lotteryFactory: LotteryFactory) {
        this.lotteryFactory = lotteryFactory
    }

    public getLotteries(): Lottery[]{
        this.checkForUpdate()

        return this.lotteries

    }

    public getLottery(id: number): Promise<Lottery> {
        let lottery = this.lotteries.filter((el: Lottery) => el.id === id)
        if (!lottery.length) {
            lottery = this.endedLotteries.filter((el: Lottery) => el.id === id)
        }

        return new Promise((resolve, reject) => {
            resolve(lottery[0])
        })
    }

    public create() {
        for (let i = 0; i < lotteryDurations.length; i++) {
            const lottery = this.lotteryFactory.createLottery(lotteryDurations[i], this.lotteryId)
            this.lotteryId = ++this.lotteryId
            this.lotteries.push(lottery)
        }
    }

    private createLottery(duration: string, index: number): void {
        const lottery = this.lotteryFactory.createLottery(duration, this.lotteryId)
        this.lotteries.splice(index, 1, lottery)
        this.lotteryId = ++this.lotteryId
    }

    private checkForUpdate(): void {
        for (let i = 0; i < this.lotteries.length - 1; i++) {
            if (new Date(this.lotteries[i].endTime).getTime() < new Date().getTime()) {
                this.endedLotteries.push(this.lotteries[i])
                this.createLottery(this.lotteries[i].duration, i)
            }
        }
    }
}

export const serverController = new ServerModuleController(new LotteryFactory())
