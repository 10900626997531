<template>
    <section class="wrapper">
        <div class="container">
            <div class="row">
                <div class="title-container">
                    <h4 class="title">{{ t("about.about-banner.title") }}</h4>
                    <p class="text">{{ t('about.about-banner.text') }}</p>
                </div>
                <img class="banner-img" src="@/assets/images/about/about.png" alt="about" />
            </div>
        </div>
    </section>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const {t} = useI18n()
</script>

<style lang="scss" scoped>
.wrapper {
    background: #070b28;
}

.row {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
}

.banner-img {
    display: block;
    max-width: 70%;
    margin-bottom: 1rem;
}

.title-container {
    position: relative;
    text-align: center;
    max-width: 100%;
    width: 100%;
}

.text {
    text-align: start;
}

@media screen and (max-width: 576px) {
    .title-container {
        max-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 786px) {
    .title {
        font-size: 34px;
    }

    .text {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 576px) {
    .banner-container {
        border-bottom: 1px solid rgba(255, 255, 255, 0.04);
    }
}

@media screen and (min-width: 786px) {
    .row {
        flex-direction: row;
    }

    .title-container {
        text-align: start;
    }

    .banner-img {
        max-width: 60%;
        margin-bottom: 0;
    }
}

@media screen and (min-width: 992px) {
    .banner-img {
        max-width: 59%;
    }

    .title-container {
        flex: 0 0 40%;
        max-width: 40%;
    }
}
</style>
