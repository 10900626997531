import { getRd } from "../getRd"

//6-20 characters, uppercase or lowercase letters or numbers
export function setChildProductId() {
    let code = ''
    const len = getRd(6, 20)
    for (let i = 0; i < len; i++) {
        const type = getRd(1, 3)
        switch (type) {
            case 1:
                code += String.fromCharCode(getRd(48, 57))
                break
            case 2:
                code += String.fromCharCode(getRd(65, 90))
                break
            case 3:
                code += String.fromCharCode(getRd(97, 122))
                break
        }
    }

    return code
}
