import { Ticket } from '@/constants'
import { ref, computed, ComputedRef, Ref, ReactiveEffect } from 'vue'

export default function handlePagination(data: any) {
    let page = ref(1)

    const perPage = 10

    const paginatedData = computed(() => data.value.slice((page.value - 1) * perPage, page.value * perPage))

    const nextPage = () => {
        if (page.value !== Math.ceil(data.value.length / perPage)) {
            page.value += 1
        }
    }

    const backPage = () => {
        if (page.value !== 1) {
            page.value -= 1
        }
    }

    const goToPage = (numPage: number) => {
        page.value = numPage
    }

    return { data, paginatedData, perPage, page, nextPage, backPage, goToPage }
}
