<template>
    <section class="wrapper">
        <div class="container">
            <div class="row">
                <div class="title-container">
                    <h2 class="title">{{ $t('lottery-catalog.banner.title') }}</h2>
                    <p class="text">
                        {{ $t('lottery-catalog.banner.text') }}
                    </p>
                </div>
                <img src="@/assets/images/lotteryCatalog/lottery.png" alt="lottery" />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.wrapper {
    background: #070b28;
    border-bottom: 1px solid rgba(255, 255, 255, 0.04);

    .row {
        align-items: center;
        flex-direction: column-reverse;
    }

    img {
        display: block;
        max-width: 85%;
        width: 100%;
        margin-bottom: 1rem;
    }

    .title-container {
        width: 100%;
        margin: auto 0;
    }

    .text {
        margin-bottom: 0;
        text-align: left;
    }
}

@media screen and (min-width: 786px) {
    .wrapper {
        padding: 5.4rem 10%;

        .row {
            flex-direction: row;
        }

        .title-container {
            width: 50%;

            .text {
                text-align: start;
            }
        }

        img {
            width: 50%;
            margin-bottom: 0;
        }
    }
}

// @media screen and (min-width: 992px) {
//     .container {
//         padding-left: 5rem;
//     }
// }
// @media screen and (min-width: 1800px) {
//     .container {
//         padding-left: 0;
//     }
// }
</style>
