<template>
    <section class="wrapper">
        <div class="container">
            <div class="row">
                <div class="title-wrapper">
                    <h4 class="title">{{ $t('winners.banner.title') }}</h4>
                    <p class="text">{{ $t('winners.banner.text') }}</p>
                </div>
                <img class="bc-img" src="@/assets/images/winners/tournaments.png" alt="tournaments" />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.wrapper {
    background: #070b28;

    .bc-img {
        max-width: 70%;
        vertical-align: middle;
        margin-bottom: 1rem;
    }

    .title-wrapper {
        position: relative;
        width: 100%;

        .title {
            font-size: 24px;
            line-height: 34px;
        }

        .text {
            margin-bottom: 0;
            text-align: inherit;
        }
    }
}

.row {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 576px) {
    .wrapper {
        .title {
            font-size: 40px;
            line-height: 50px;
        }
    }
}

@media screen and (min-width: 786px) {
    .wrapper {
        .row {
            flex-direction: row;
        }

        .text {
            margin-bottom: 2.5rem;
        }

        .bc-img {
            margin-bottom: 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .wrapper {
        .bc-img {
            max-width: 40%;
        }

        .title-wrapper {
            flex: 0 0 50%;
            max-width: 50%;

            .title {
                font-size: 70px;
                line-height: 80px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {}
</style>
